import { useNavigation } from '@react-navigation/native'
import * as Clipboard from 'expo-clipboard'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  useWindowDimensions,
  View,
} from 'react-native'
import { Button, Card, IconButton, Text } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { useAuthContext } from '../../../auth/auth-context'
import Row from '../../../components/containers/Row'
import { Icon } from '../../../components/Icon'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import { Env } from '../../../env'
import { useTheme } from '../../../hooks/use-theme'
import { AppNavigation } from '../../../navigation/types'
import {
  IconAdd,
  IconClusterSettings,
  IconPrivate,
} from '../../../utils/meldd-icons'
import { Article, Cluster } from '../types'
import { UserLink } from './ClickableClusterMember'
import { ImportButton } from './ImportButton'
import { PerspectiveTile } from './PerspectiveTile'

interface ClusterCardProps {
  cluster: Cluster
  onRefresh: () => void
  refreshing: boolean
}

export function ClusterCard(props: ClusterCardProps) {
  const { width } = useWindowDimensions()
  const { t } = useTranslation('clusters')
  const theme = useTheme()
  const { authAction } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()
  const { cluster, refreshing, onRefresh } = props
  const styles = useStyle()
  const saveClusterUrl = (cluster: Cluster) => {
    let urlToClipBoard = `${Env.WEB_APP_URL}/ClusterCards?clusterId=${cluster.id}&nodeId=${cluster.nodeId}`

    Toast.show('URL copied!', {
      position: Toast.positions.TOP,
    })
    Clipboard.setString(urlToClipBoard)
  }

  const renderListItems = useCallback(
    (info: ListRenderItemInfo<Article>) => {
      return <PerspectiveTile cluster={cluster} perspective={info.item} />
    },
    [cluster.articles, cluster.articleVotes]
  )
  const createArticle = () => {
    authAction(navigation, () =>
      navigation.navigate('CreateArticle', {
        clusterId: cluster.id,
      })
    )
  }

  return (
    <Card
      style={styles.card}
      elevation={5}
      mode={'elevated'}
      contentStyle={{ flex: 1 }}
    >
      <View style={styles.titleWrapper}>
        <View style={styles.title}>
          <View style={styles.mainTitle}>
            <Text
              variant={width < 480 ? 'titleSmall' : 'titleMedium'}
              numberOfLines={15}
              ellipsizeMode={'tail'}
            >
              {cluster.topic}
            </Text>
            <IconButton
              mode="contained-tonal"
              size={20}
              icon="content-copy"
              onPress={() => saveClusterUrl(cluster)}
            />
            {cluster.isModerator && (
              <IconButton
                style={styles.button}
                onPress={() =>
                  navigation.navigate('ClusterSettings', {
                    clusterId: cluster.id,
                  })
                }
                icon={IconClusterSettings}
              />
            )}
          </View>

          <View
            style={[
              styles.subTitle,
              {
                marginTop:
                  cluster.moderators.length > 0
                    ? theme.spacing(0)
                    : theme.spacing(3),
              },
            ]}
          >
            {cluster.isPrivate ? (
              <>
                <Icon
                  source={IconPrivate}
                  size={16}
                  color={theme.colors.onSurfaceVariant}
                />
                <Text variant={'bodySmall'}>{t('Private')}</Text>
              </>
            ) : (
              <Text variant="bodySmall">{t('Public')}</Text>
            )}
          </View>

          {cluster.moderators.length > 0 && (
            <View style={styles.moderators}>
              <Text variant={'bodySmall'}>{t('Moderators')}:</Text>
              {cluster.moderators.map((moderator, index) => (
                <UserLink key={index} user={moderator.user} />
              ))}
            </View>
          )}
        </View>
      </View>
      <Card.Content style={styles.content}>
        <Row
          columnGap={4}
          style={{ padding: theme.spacing(4) }}
          justifyContent="space-between"
        >
          <ImportButton clusterId={cluster.id} />

          {width > 400 ? (
            <Button
              mode="contained"
              icon={IconAdd}
              onPress={() => createArticle()}
            >
              {t('Perspective')}
            </Button>
          ) : (
            <IconButton
              mode="contained"
              icon={IconAdd}
              onPress={() => createArticle()}
            />
          )}
        </Row>
        <FlatList
          data={cluster.articles}
          renderItem={renderListItems}
          extraData={cluster.articleVotes}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </Card.Content>
    </Card>
  )
}

const useStyle = createStyle(({ theme }) => ({
  card: {
    flex: 1,
    height: 1, // To trigger the flex on iOS
    flexDirection: 'column',
  },
  content: {
    backgroundColor: theme.colors.surface,
    flex: 1,
    paddingHorizontal: 0,
    margin: 0,
  },
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing(1),
    paddingHorizontal: theme.spacing(4),
  },
  title: {
    flex: 1,
  },
  mainTitle: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
  },
  button: {
    marginRight: -theme.spacing(1),
    justifyContent: 'center',
  },
  subTitle: {
    color: theme.colors.onSurfaceVariant,
    alignContent: 'center',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
  },
  moderators: {
    flexDirection: 'row',
    columnGap: theme.spacing(2),
    marginTop: theme.spacing(1),
    overflow: 'hidden',
  },
  moderator: {
    flexShrink: 1,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    paddingVertical: theme.spacing(3),
    paddingHorizontal: theme.spacing(4),
  },
}))
